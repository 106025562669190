import { Box, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';
import { CartWrapper } from './style';
import PrimaryBtn from '../../components/FormCollection/PrimaryBtn';
import CartTotal from '../../components/cart/CartTotal';
import ContentLoader from '../../components/shared/ContentLoader';
import useTitle from '../../hooks/useTitle';
import { getCurrency } from '../../Utils/format';
import CartBookingCard from '../../components/cart/BookingCard';
import BackToHomeButton from '../../components/shared/BackToHomeButton';
import { AddonPurchase } from '../../hooks/useCart';
import EmptyState from '../../components/shared/EmptyState';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
interface IProps {
  title: string;
}
const Cart: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const naviagte = useNavigate();
  const { bookingCart, company, isCartLoading } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const getCartTotal = () => {
    let price = bookingCart.reduce((prev, cur) => {
      if (cur.addonsPurchases) {
        let addonsPrice: number = cur.addonsPurchases.reduce(
          (prev2: number, cur2: AddonPurchase) => prev2 + cur2.paidAmount,
          0
        );

        return prev + addonsPrice + cur.price * cur.quantity;
      } else return prev;
    }, 0);

    return price;
  };

  return (
    <ContentLoader isLoading={isCartLoading}>
      <CartWrapper>
        <Box className="content">
          <Box className="details">
            {bookingCart.length > 0 ? (
              <>
                <Stack sx={{ width: '100%', gap: '14px' }}>
                  {bookingCart.map(booking => (
                    <CartBookingCard
                      booking={booking}
                      key={booking.id}
                      showRemoveFromCartOption={true}
                    />
                  ))}
                </Stack>
                <Stack
                  className="details"
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginY: '34px',
                  }}
                >
                  <CartTotal
                    title="Cart Total"
                    value={getCartTotal()}
                    currency={getCurrency(company?.currency)}
                  />
                </Stack>
                <Box className="details">
                  <PrimaryBtn
                    onClick={() => {
                      naviagte(`/CartCheckout?companyId=${company?.id}`);
                    }}
                    text="Proceed To Checkout"
                    data-testid="proceed-to-checkout-button"
                  />
                </Box>
              </>
            ) : (
              <>
                <EmptyState
                  message="Your cart is empty."
                  icon={<ShoppingCartIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />}
                  containerSx={{ minHeight: '50vh' }}
                />
                <Box className="details">
                  <BackToHomeButton />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </CartWrapper>
    </ContentLoader>
  );
};

export default Cart;
