import React, { useContext, useState } from 'react';
import DaySelector from '../../components/Calendar/DaySelector';
import { CheckoutContext, CheckoutContextProps } from '../../context/CheckoutContext';

import useTitle from '../../hooks/useTitle';

import dayjs from 'dayjs';
import { CoachCalendarWrapper } from './style';
import Heading from '../../components/shared/Heading';
import TimeSelector from '../../components/Calendar/TimeSelector';
import useAvailableStartingTimes from '../../hooks/useAvailableStartingTimes';
import useCart from '../../hooks/useCart';
import { useDaySelection } from '../../hooks/useDaySelection';
import ServiceSelector from '../../components/Calendar/ServiceSelector';
import { useSearchParams } from 'react-router-dom';

interface IProps {
  title: string;
}

const CoachCalendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get('serviceId');


  const { selectedWeekStartDate, setSelectedWeekStartDate } = useDaySelection();

  const {
    selectedService,
    company,
    selectedDay,
    setSelectedDay,
    selectedBranch,
    selectedResource,
    isAllResourcesOptionEnabled,
    availableStartingTimesResource,
    bookingCart,
    setQuantity,
    isCartLoading,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const { addToCart } = useCart();

  const { loading: isLoadingAvailabilitySlots } = useAvailableStartingTimes({
    companyId: company?.id,
    branchId: selectedBranch,
    serviceId: selectedService,
    selectedDay,
    selectedResource,
    isAllResourcesOptionEnabled,
    bookingCart,
    selectedWeekStartDate,
  });

  setQuantity(1);
  return (
    <CoachCalendarWrapper>
      <Heading heading="Book a Coach" />
      <ServiceSelector type="coach" serviceId={serviceId || ''} />

      <DaySelector
        onlySevenDaysInAdvance={false}
        dayHasAvailableSlots={new Map()}
        selectedWeekStartDate={selectedWeekStartDate}
        setSelectedWeekStartDate={setSelectedWeekStartDate}
      />

      <TimeSelector
        availableStartingTimes={availableStartingTimesResource!}
        isLoadingAvailabilitySlots={isLoadingAvailabilitySlots || isCartLoading}
        showDurationFilter={true}
        timeUnit="Hour"
      />
    </CoachCalendarWrapper>
  );
};

export default CoachCalendar;
