import { Box, InputLabel, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { IPhoneFormInput } from './Types';

const PhoneFormInput: React.FC<IPhoneFormInput> = ({ control, error, name, label, dataTestId }) => {
  const theme = useTheme();
  return (
    <Box sx={{ marginBottom: '20px' }} data-testid={dataTestId}>
      <InputLabel
        sx={{
          fontSize: '18px',
          fontWeight: 500,
          marginBottom: '16px',
          alignSelf: 'flex-start',
          color: '#171717',
        }}
      >
        {label}
      </InputLabel>

      <Controller
        control={control}
        name={name as any}
        render={({ field }) => (
          <PhoneInput
            {...field}
            country={'ae'}
            buttonStyle={{
              position: 'static',
              border: 0,
              borderRadius: '16px',
              padding: '9px 18px',
              background: '#fff',
              height: '70px',
            }}
            containerStyle={{
              display: 'flex',
              flexDirection: 'row-reverse',
              gap: '14px',
              height: '70px',
              boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
              borderRadius: '16px',
            }}
            inputStyle={{
              flex: 1,
              padding: '10px 18px',
              borderRadius: '16px',
              border: 0,
              fontSize: '16px',
              height: '70px',
              width: '100%',
              background: '#fff',
            }}
          />
        )}
      />
      {error && (
        <Typography sx={{ fontSize: '12px', color: 'red', marginTop: '8px' }}>
          {error.message}
        </Typography>
      )}
    </Box>
  );
};

export default PhoneFormInput;
