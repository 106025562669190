import React from 'react';
import { Box, Typography, SxProps } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';

interface EmptyStateProps {
  message: string;
  icon?: React.ReactNode;
  containerSx?: SxProps;
  messageSx?: SxProps;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  message,
  icon = <InboxIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />,
  containerSx,
  messageSx
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '2rem',
        ...containerSx
      }}
    >
      {icon}
      <Typography
        sx={{
          textAlign: 'center',
          color: 'text.secondary',
          ...messageSx
        }}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default EmptyState; 